<template>
  <b-modal
    id="modal-cancelar-seguro"
    title="Deseja cancelar o seguro?"
    size="md"
    variant="dark"
    :visible="showModalCancelarSeguro"
    @hide="hideModal"
  >
    <div class="alert alert-danger">
      ATENÇÃO: Após cancelar, a bicicleta ficará sem seguro e sem nenhuma
      cobertura.
    </div>
    <hr />
    <div class="form-group">
      <label>Poderia nos dizer o motivo do cancelamento?</label>
      <select class="form-control" v-model="motivo">
        <option :value="undefined">Selecione...</option>
        <option
          v-for="(item, index) in config.MOTIVOS_CANCELAR_SEGURO"
          :key="'motivo_cancelamento_' + index"
          :value="item.value"
          >{{ item.text }}</option
        >
      </select>
    </div>
    <div class="form-group">
      <label>Algum comentário ou sugestão para nós?</label>
      <textarea class="form-control" v-model="sugestao"></textarea>
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <div class="row mx-0 w-100">
        <div class="col-6">
          <button
            class="btn btn-danger btn-block"
            :disabled="verificarBloquearCancelar()"
            @click="onClickCancelar"
          >
            <span v-if="loadingCancelar">Cancelando...</span>
            <span v-else>Cancelar o seguro</span>
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-success btn-block" @click="cancel">
            Manter o seguro
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";

export default {
  props: ["showModalCancelarSeguro", "idSeguro", "idUsuarioAdm"],
  data() {
    return {
      config,
      loadingCancelar: false,
      motivo: undefined,
      sugestao: undefined,
    };
  },
  components: {},
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.motivo = undefined;
      this.sugestao = undefined;
    },
    async onClickCancelar() {
      this.loadingCancelar = true;

      await this.cancelarSeguro(this.idSeguro);

      this.loadingCancelar = false;

      this.modificou = true;

      return this.hideModal();
    },
    async cancelarSeguro(idSeguro) {
      if (!this.loadingCancelar) return false;

      try {
        var data = {
          motivo: this.motivo,
          sugestao: this.sugestao,
          idUsuario: this.idUsuarioAdm,
        };
        //TODO: migrar para a v2 só quando a essor disponibilizar os ambientes de homologação e de produção(testar fluxo completo);
        await axios.post(`${config.API_URLV2}/seguro/${idSeguro}/cancelar`, data);

        Swal.fire({
          title: "Seguro cancelado com sucesso.",
          icon: "success",
        });

        return true;
      } catch (e) {
        captureError(e);
        if (e?.response?.data?.error) {
          this.$toast.error();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: e?.response?.data?.error,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text:
              "Não foi possível cancelar o seguro. Tente novamente mais tarde ou contate o suporte.",
          });
        }
        return false;
      }
    },
    verificarBloquearCancelar() {
      return (
        this.loadingCancelar ||
        !this.motivo ||
        (this.motivo == config.MOTIVOS_CANCELAR_SEGURO_VALUES.OUTROS &&
          !this.sugestao?.trim())
      );
    },
  },
};
</script>
